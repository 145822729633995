<template lang="">
  <IAmOverlay :loading="isLoading">
    <BRow>
      <BCol cols="2">
        <div class="d-flex flex-column gap-3 p-2">
          <BButton
            pill
            variant="airline"
            class="w-100"
            :disabled="isLoading"
            @click="onExportPDFHandle"
          >
            {{ $t('invoice.btn.exportPDF') }}
          </BButton>
          <BButton
            pill
            variant="outline-secondary"
            @click="handleExit"
          >
            {{ $t('invoice.btn.exit') }}
          </BButton>
        </div>
      </BCol>
      <BCol
        cols="10"
        style="overflow: auto; max-height: 100vh"
      >
        <div class="p-1 border-1 border-dark">
          <VuePdfEmbed
            v-if="pdfData"
            ref="pdfFileRef"
            :source="pdfData"
          />
        </div>
      </BCol>
    </BRow>
  </IAmOverlay>
</template>
<script>
import { ref, computed } from '@vue/composition-api'
import { BButton, BCol, BRow } from 'bootstrap-vue'
import VuePdfEmbed from 'vue-pdf-embed/dist/vue2-pdf-embed'

import { useRouter } from '@/@core/utils/utils'
import store from '@/store'

import useToast from '@useToast'

import useIncorrectInvoiceHandle, { useIncorrectInvoiceDetailHandle } from './useIncorrectInvoiceHandle'
import incorrectInvoiceStoreModule from './incorrectInvoiceStoreModule'

export default {
  components: {
    BRow,
    BCol,
    BButton,
    IAmOverlay: () => import('@/components/IAmOverlay.vue'),
    VuePdfEmbed,
  },
  setup() {
    const { router } = useRouter()

    const { INCORRECT_INVOICE_STORE } = useIncorrectInvoiceHandle()
    const { getIncorrectInvoicePdfById, getIncorrectInvoiceById, loadingDetail } = useIncorrectInvoiceDetailHandle()
    const { toastSuccess } = useToast()
    const pdfFileRef = ref()
    const pdfData = ref()
    // Register module
    if (!store.hasModule(INCORRECT_INVOICE_STORE)) store.registerModule(INCORRECT_INVOICE_STORE, incorrectInvoiceStoreModule)

    const incorrectInvoiceData = ref()
    const loadingInitData = ref(false)
    const fetchIncorrectInvoicePdf = async (id = router.currentRoute.params.id) => {
      loadingInitData.value = true
      const promiseArr = [
        getIncorrectInvoicePdfById(id),
        getIncorrectInvoiceById(id),
      ]
      const res = await Promise.all(promiseArr)
      pdfData.value = URL.createObjectURL(res[0].data)
      incorrectInvoiceData.value = res[1]
      loadingInitData.value = false
    }

    if (router.currentRoute.params.id) {
      fetchIncorrectInvoicePdf(router.currentRoute.params.id)
    }

    function handleExit() {
      window.close()
    }

    function onExportPDFHandle() {
      const fileName = `${incorrectInvoiceData.value.notificationNumber}.pdf`
      pdfFileRef.value.download(fileName)
      toastSuccess('invoice.incorrectInvoices.msg.exportPdfSuccess')
    }

    const isLoading = computed(() => loadingDetail.value || loadingInitData.value)

    return {
      pdfData,
      isLoading,
      pdfFileRef,
      handleExit,
      onExportPDFHandle,
    }
  },
}
</script>
<style lang="">

</style>
