var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('IAmOverlay', {
    attrs: {
      "loading": _vm.isLoading
    }
  }, [_c('BRow', [_c('BCol', {
    attrs: {
      "cols": "2"
    }
  }, [_c('div', {
    staticClass: "d-flex flex-column gap-3 p-2"
  }, [_c('BButton', {
    staticClass: "w-100",
    attrs: {
      "pill": "",
      "variant": "airline",
      "disabled": _vm.isLoading
    },
    on: {
      "click": _vm.onExportPDFHandle
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('invoice.btn.exportPDF')) + " ")]), _c('BButton', {
    attrs: {
      "pill": "",
      "variant": "outline-secondary"
    },
    on: {
      "click": _vm.handleExit
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('invoice.btn.exit')) + " ")])], 1)]), _c('BCol', {
    staticStyle: {
      "overflow": "auto",
      "max-height": "100vh"
    },
    attrs: {
      "cols": "10"
    }
  }, [_c('div', {
    staticClass: "p-1 border-1 border-dark"
  }, [_vm.pdfData ? _c('VuePdfEmbed', {
    ref: "pdfFileRef",
    attrs: {
      "source": _vm.pdfData
    }
  }) : _vm._e()], 1)])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }